@import '../../styles/index';

.pr-comowner {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
		margin-top: 70.5px;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;				
	}

	&__content {
		@extend .pr-content;
		padding-top: 40px;
		padding-bottom: 40px;

		display: flex;
		justify-content: center;
		align-items: center;		
	}

	&__page {
		width: 80%;		
	}

	&__page-name {
		font-size: 23px;
		font-weight: 600;
		color: #3b3a3a;
	}

	&__form {
		margin-top: 30px;
		padding: 20px;
		background-color: #e4e4e4;
	}

	&__input {
		& .note {
			font-size: 15px;
			color: rgb(80 85 92);			
		}

		& input {
			margin-top: 2px;
			width: 350px;
			border: none;
			border: 1px solid gray;
			height: 33px;
			padding: 2px 10px;

			@media only screen and (max-width: 1024px) {
				width: 100%;
			}				
		}

		&:not(:first-child) {
			margin-top: 20px;
		}
	}

	&__actions {
		margin-top: 20px;

		& button {
			width: 120px;
			display: block;
			border: none;
			border: 1px solid #d9d9d9;
			padding: 8px 16px;
			font-size: 16px;
			cursor: pointer;
			text-decoration: none;
			color: white;
			border: 1px solid #687186;
			background-color: rgb(34, 47, 62);						

			&:disabled {
				background-color: $btn-disabled;
			}

			@media only screen and (max-width: 786px) {
				padding: 5px 13px;
				font-size: 14px;
			}
		}
	}
}



