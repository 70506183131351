@import '../../styles/index';

.pr-itext {
	$selector: &;
	width: 100%;

	&__inputwrap {		
		width: 100%;
		height: 33px;
		border: 1px solid gray;		
		font-size: 15px;

		&.invalid {
			border: 1px solid red;
		}				

		@media only screen and (max-width: 786px) {			
			font-size: 13px;							
		}

		& input {
			border: none;
			width: 100%;
			height: 100%;
			padding: 2px 10px;
		}
	}

	&__inputinfo {		
		margin-left: 1px;
		font-size: 12px;
		color: red;
		padding: 8px 0;
	}
}
