@import '../../styles/index';

.pr-faqs {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
		margin-top: 70.5px;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;				
	}

	&__content {
		@extend .pr-content;
		padding-top: 40px;
		padding-bottom: 40px;

		display: flex;
		justify-content: center;
		align-items: center;		
	}

	&__page {
		display: flex;
		flex-flow: column;
		justify-content: center;
		width: 80%;		
	}

	&__page-name {
		font-size: 23px;
		font-weight: 600;
		color: #3b3a3a;
	}

	&__details {
		margin-top: 30px;			
	}

	&__qarow {				
		padding: 20px;
		background-color: #e4e4e4;

		&:not(:first-child) {
			margin-top: 20px;
		}
		
		& .question {
			font-size: 18px;
			font-weight: 500;
			color: rgb(0 78 167);
		}

		& .answer {
			margin-top: 5px;		
			font-size: 16px;

			& .italic {
				font-style: italic;
				font-weight: 600;
			}
		}
	}
}
