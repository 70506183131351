@import '../../styles/index';

.pr-privacy {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
		margin-top: 70.5px;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;				
	}

	&__content {
		@extend .pr-content;
		padding-top: 40px;
		padding-bottom: 40px;

		display: flex;		
		justify-content: center;
		align-items: center;				
	}

	&__page {		
		width: 80%;
	}

	&__page-name {
		font-size: 23px;
		font-weight: 600;
		color: #3b3a3a;
	}

	&__details {
		padding: 20px;
		background-color: #e4e4e4;

		margin-top: 20px;		
		font-size: 16px;		
	}
}
