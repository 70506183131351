@import '../../styles/index';

.pr-cart {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
		margin-top: 70.5px;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;				
	}

	&__content {
		@extend .pr-content;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	&__cart-title {
		font-size: 20px;
	}	

	&__itemtable {
		width: 100%;
		border: 1px solid #d8d7d7;

		&.empty-cart {
			border-top: none;
		}	

		& .row {
			width: 100%;
			text-align: left;			
			padding: 8px 12px;
			background-color: #f5f5f5;			

			&:not(:first-child) {
				border-top: 1px solid #d8d7d7;
			}

			@media only screen and (max-width: 786px) {
				padding: 18px 16px;

				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		& .container {
			display: flex;
			align-items: center;					

			@media only screen and (max-width: 786px) {
				width: 300px;
				align-items: flex-start;
			}			
		}

		& .logo {			
			flex: 0 0 32px;	
			display: flex;
			align-items: center;

			& img {
				width: 100%;
			}

			@media only screen and (max-width: 786px) {
				width: 42px;
				flex: 0 0 42px;	
				align-items: flex-start;
			}
		}

		& .details {
			margin-left: 12px;			
			flex: 1;
			display: flex;			

			@media only screen and (max-width: 786px) {
				flex-flow: column;
				margin-left: 20px;
			}			
		}

		& .name {				
			min-width: 220px;
			@media only screen and (max-width: 786px) {
				min-width: 42px;			
				margin-top: -2px;				
			}
		}

		& .actions {
			flex: 1;			
			margin-left: 12px;
			min-width: 200px;
			display: flex;
			align-items: end;				

			@media only screen and (max-width: 786px) {
				margin-left: 0;
				margin-top: 8px;
				min-width: 0;
			}

			& .stuks {				
				width: 100px;			
				background-color: #fdfdfd;
				border: 1px solid #bebebe;
				border-radius: 15px;
				padding: 1px 4px;

				display: flex;
				justify-content: space-evenly;
				align-items: center;
				font-size: 14px;				

				& p {					
					padding: 1px 3px;
					cursor: pointer;
				}

				& i {
					font-size: 14px;
					&.disabled {
						color: #c0bebe;
					}															

					@media only screen and (max-width: 786px) {
						font-size: 13px;
					}
				}
			}

			& .delete {
				margin-left: 14px;
				cursor: pointer;
				color: #303030;

				@media only screen and (max-width: 786px) {
					font-size: 17px;
				}											
			}
		}

		& .price {
			min-width: 100px;			

			@media only screen and (max-width: 786px) {				
				margin-top: 8px;
				font-size: 18px;
			}
		}
	}

	&__price-details {
		background-color: rgb(240, 246, 255);
	  	border-bottom: 1px solid #d8d7d7;
		border-left: 1px solid #d8d7d7;
		border-right: 1px solid #d8d7d7;

		padding: 18px 12px 8px 12px;
		width: 100%;
		display: flex;
		justify-content: center;

		&.empty-cart {			
			padding: 40px;
		}

		& table td {
			min-width: 130px;
		}

		& table td.label {	
			font-size: 18px;

			& .info {
				font-size: 16px;				
				color: #636262;
			}

			@media only screen and (max-width: 786px) {
				font-size: 15px;
			}	
		}

		& table td.amount {
			text-align: right;
			font-size: 22px;

			@media only screen and (max-width: 786px) {
				font-size: 16px;
			}	
		}	

		& table td.grand-label {	
			font-size: 22px;
			font-weight: 600;

			@media only screen and (max-width: 786px) {
				font-size: 18px;
			}	
		}

		& table td.grand-amount {
			text-align: right;
			font-weight: bold;
			font-size: 24px;
			color: blue;

			@media only screen and (max-width: 786px) {
				font-size: 20px;
			}	
		}

		& table tr.empty-cart div {
			color: #c1bcbc;
			font-size: 20px;			
		}
	}

	&__shop, &__buyer {
		margin-top: 60px;
		width: 100%;
		background-color: #f5f5f5;

		padding: 30px 30px 20px 30px;
		border: 1px solid #d8d7d7;		 	
	}

	&__form-label {
		@media only screen and (max-width: 786px) {
			font-size: 12px;
		}
	}

	&__buyer {
		margin-top: 55px;
		padding: 20px 30px 15px 30px;
	}

	&__form-label {
		font-size: 20px;
	}

	&__form {
		margin: 20px 20px 20px 0;

		@media only screen and (max-width: 786px) {
			margin: 16px 0px;
		}

		& .row {
			width: 100%;			
			display: flex;
			justify-content: space-between;			

			&:not(:first-child) {
				margin-top: 10px;
			}

			@media only screen and (max-width: 786px) {
				flex-flow: column;
			}
		}

		& .row-1, .row-2 {
			flex: 1;
			display: flex;												
		}		

		& .row-2 {
			margin-left: 4%;

			@media only screen and (max-width: 786px) {
				margin-left: 0;
				margin-top: 3%;
			}		
		}	
	}

	&__actions {
		margin-top: 30px;
		margin-bottom: 30px;

		display: flex;
		justify-content: flex-end;

		@media only screen and (max-width: 786px) {
			justify-content: center;
		}

		& button, a {
			height: 40px;
			display: block;
			border: none;
			border: 1px solid #d9d9d9;
			padding: 6px 16px;
			font-size: 16px;
			cursor: pointer;
			text-decoration: none;
			color: black;
			background-color: #f5f5f5;			

			&:not(:first-child) {
				margin-left: 10px;
			}

			@media only screen and (max-width: 786px) {
				padding: 5px 13px;
				font-size: 14px;
			}
		}

		& .order-now {
			color: white;
			background-color: rgb(34, 47, 62);
			border: 1px solid #687186;

			&:disabled {
				background-color: $btn-disabled;
			}
		}

		& .btn-wrap {
			margin-left: 10px;
		}
	}
}
