@import '../../styles/index';

.pr-details {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
		margin-top: 70.5px;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;				
	}

	&__content {
		@extend .pr-content;
		padding-top: 40px;
		padding-bottom: 40px;		

		display: flex;
		justify-content: center;
		align-items: center;		
	}

	&__stockinfo {
		display: flex;

		@media only screen and (max-width: 786px) {
     		flex-flow: column;
     		align-items: center;
		}			

		& .logo img {
			width: 120px;

			@media only screen and (max-width: 786px) {
				width: 90px;
			}
		}

		& .details {
			margin-left: 25px;
			width: 350px;

			@media only screen and (max-width: 786px) {
     			margin-left: 0;
     			margin-top: 25px;
     			width: 250px;

     			display: flex;
     			flex-flow: column;
     			align-items: center;
			}
		}

		& .name {
			font-family: "Prata", serif;
			font-weight: 500;			
			font-size: 22px;
		}

		& .about {
			margin-top: 4px;
			line-height: 1;

			@media only screen and (max-width: 786px) {
				text-align: center;
				font-size: 16px;
			}
		}

		& .price-details {
			margin-top: 25px;
			display: flex;
			line-height: 1.2;
		}

		& .stock-price {
			& .price {
				display: flex;				
				min-width: 170px;				
				border-radius: 3px;			
			}

			& .amount {				
				font-weight: 600;
				font-size: 33px;
				color: green;
				line-height: 0.8;			

				@media only screen and (max-width: 786px) {
					font-size: 28px;
				}
			}			

			& .label {				
				margin-left: 5px;		
				font-size: 18px;
				font-weight: 600;			
				display: flex;
				flex-flow: column;
				justify-content: end;

				@media only screen and (max-width: 786px) {
					font-size: 18px;
				}
			}				

			&.stock-price-empty {
				@extend .skeleton-box;
				pointer-events: none;			
				height: 32px;
				padding: 18px 12px 12px 12px;
				width: 190px;

				@media only screen and (max-width: 786px) {
					width: 120px;					
				}				
			}
		}		

		& .actions {
			margin-top: 12px;
			&.queried {
				margin-top: 20px;
			}

			& .total-cart {				
				width: 190px;
				padding: 0;
				margin: 0;
				color: #2882ff;					

				display: flex;
				justify-content: space-between;
				align-items: center;											
				
				& a.link {					
					text-align: center;				
					font-size: 15px;
					font-weight: 600;
					padding: 0;
					margin: 0;

					display: inline;
														
					color: #2882ff;					
					text-decoration: none;																															
				}
			}		

			& button, .btn-skeleton {
				display: block;
				min-width: 190px;				
				padding: 8px 10px;
				font-size: 15px;
				cursor: pointer;			

				&:not(:first-child) {
					margin-top: 10px;
				}				

				@media only screen and (max-width: 786px) {					
					font-size: 13px;
					padding: 5px 6px;
				}
			}

			& .cart.btn-skeleton {
				height: 18px;
			}

			& .btn-skeleton {				
				border: none;
				@extend .skeleton-box;
				height: 32px;
				width: 170px;				
			}		

			& button.add-to-cart {
				border: none;
				border: 1px solid #d6d6d6;
				font-size: 14px;
			}

			& button.checkout {
				display: inline-block;
				color: white;
				background-color: rgb(34, 47, 62);
				border: 1px solid #687186; 
				text-decoration: none;
				text-align: center;
				font-size: 15px;			

				@media only screen and (max-width: 786px) {
					font-size: 14px;
				}
			}
		}
	}
}
