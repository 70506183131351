@import '../../styles/index';

.pr-orderstatus {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
		margin-top: 70.5px;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;				
	}

	&__content {
		@extend .pr-content;
		padding-top: 40px;
		padding-bottom: 40px;

		display: flex;
		justify-content: center;
		align-items: center;		
	}

	&__message {
		min-height: 300px;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		& .status {
			font-size: 22px;
			color: green;
		} 
	}
}
