@import '../../styles/index';

.pr-header {
	$selector: &;	
	width: 100%;
	display: flex;
	justify-content: center; 

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	&__inn {
		padding: 15px 25px;
		width: $screen-inn-width;

		display: flex;
		justify-content: space-between;		
		align-items: center;

		#{$selector}.standard & {
			padding: 14px 25px;
			background-color: white;
			border-bottom: 1px solid #cdcdcd;							
		}

		@media (min-width: 786px) and (max-width: 992px) {
			width: $screen-inn-pad-width;             
		}

		@media only screen and (max-width: 786px) {
			width: $screen-inn-mobile-width;      
		}					
	}

	&__title {				
		font-family: "Montserrat", sans-serif;
		text-decoration: none;		
		color: white;
		border: 1px solid white;
		border-radius: 1px;
		display: inline-block;
		padding: 2px 8px;
		font-size: 1.5rem;

		@media only screen and (max-width: 992px) {
			font-size: 1.2rem;             
		}

		@media only screen and (max-width: 786px) {
			color: black;
			background-color: white;
			opacity: 0.8;
		}

		#{$selector}.standard & {
			color: black;
			border: 1px solid black;

			@media only screen and (max-width: 786px) {
				background: none;
				opacity: 1;  
			}							
		}

		& .a {
			font-weight: bold;
		}

		& .b {
			margin-left: 3px;
			font-weight: bold;
		}			
	}

	&__cartwrap {
		padding: 2px 8px;
		position: relative;	

		& .cart i {			
			display: inline-block;
			color: white;
			font-size: 1.5rem;

			@media only screen and (max-width: 992px) {
				font-size: 1.2rem;             
			}

			#{$selector}.standard & {
				color: black;							
			}
		}

		& .cart .count {
			position: absolute;
			top: -5px;
			right: -3px;

			height: 22px;
			width: 22px;
			border-radius: 30px;
			border: 0.5px solid black;
			background-color: white;
			display: flex;
			justify-content: center;
			align-items: center;

			@media only screen and (max-width: 992px) {
				height: 18px;
				width: 18px;            
			}

			#{$selector}.standard & {
				background-color: black;
				border: 0.5px solid white;							
			}			
		}

		& .cart .count p {
			font-size: 15px;
			color: black;
			font-weight: bold;

			@media only screen and (max-width: 992px) {
				font-size: 12px;             
			}

			#{$selector}.standard & {
				color: white;							
			}
		}
	}
}

