@import '../../styles/index';

.pr-footer {
	$selector: &;	
	width: 100%;
	display: flex;
	justify-content: center;

	&__inn {
		padding: 30px 35px;		
		width: $screen-inn-width;
		background-color: black;    	

		@media (min-width: 786px) and (max-width: 992px) {
			width: $screen-inn-pad-width;             
		}

		@media only screen and (max-width: 786px) {
			width: $screen-inn-mobile-width;      
		}						
	}

	&__row {
		@media only screen and (max-width: 992px) {
			display: flex;
			justify-content: center;			
		}		
	}

	&__wrap {		
		display: flex;
		justify-content: space-between;

		@media only screen and (max-width: 992px) {
			flex-flow: column;			
		}
	}

	&__brand {
		color: white;
		display: flex;		

		& .name {
			font-size: 20px;
			font-weight: 600;
		}

		& .notes {
			font-size: 16px;
			margin-left: 10px;
			line-height: 1.9;			
		}
	}

	&__links {
		margin-top: 3px;
		list-style: none;
		display: flex;
		font-size: 16px;	

		@media only screen and (max-width: 992px) {
			flex-flow: column;			 
		}		

		& li {
			padding: 0 20px;

			@media only screen and (max-width: 992px) {
				padding: 5px 0;			 
			}

			& a {
				color: white;
				text-decoration: none;
			}
		}
	}

	&__holding {		
		color: gray;
		font-size: 16px;
		display: flex;

		@media only screen and (max-width: 992px) {			
			justify-content: center;
			font-size: 18px;			
		}		
	}
}
