* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; 
  padding: 0;
  margin: 0;
}

body {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 

  letter-spacing: .05px;
  line-height: 1.5;  
}

p {
  margin: 0;
  padding: 0;
}

.pr-base {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f5f5f5;
}

.pr-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.pr-container-inn {
  width: $screen-inn-width;

  @media (min-width: 786px) and (max-width: 992px) {
    width: $screen-inn-pad-width;             
  }

  @media only screen and (max-width: 786px) {
    width: $screen-inn-mobile-width;      
  }
}

.pr-content {
  padding: 18px 35px;
  min-height: 78vh;
}
