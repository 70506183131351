@import '../../styles/index';

.pr-hiworks {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
		margin-top: 70.5px;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;				
	}

	&__content {
		@extend .pr-content;
		padding-top: 40px;
		padding-bottom: 40px;

		display: flex;
		justify-content: center;
		align-items: center;		
	}

	&__page {		
		width: 80%;
	}

	&__page-name {
		font-size: 23px;
		font-weight: 600;
		color: #3b3a3a;
	}

	&__details {
		margin-top: 20px;		
		font-size: 16px;

		& ol {			
			paddin: 0;			
		}

		& ol li {
			list-style: none;
			padding: 10px 10px;
			background-color: #e4e4e4;		
			&:not(:first-child) {
				margin-top: 14px;
			}

			display: flex;			

			& .circle {
				flex: 1 0 24px;
				width: 24px;
				height: 24px;
				background-color: black;
				border-radius: 20px;
				color: white;

				display: flex;
				justify-content: center;
				align-items: center;

				&.gift {
					background-color: blue;
				}
			}

			& p {
				margin-left: 12px;
				& span {
					font-weight: 600;
				}
			}
		}	
	}

	&__regulations {
		margin-top: 20px;
		font-size: 16px;
		color: #3c3b3b;

		& a {
			font-size: 16px;
		}
	}
}
