@import '../../styles/index';

.pr-home {
	$selector: &;
	@extend .pr-base;	

	&__container {
		@extend .pr-container;
	}

	&__container-inn {
		@extend .pr-container-inn;
		background-color: white;
		border: 0.5px solid #dddddd;		
	}

	&__banner {
		position: relative;	

		& .bg-image {
			width: 100%;
		}
	}

	&__banner-fg {
		position: absolute;	
		top: 100px;		
		width: 240px;
		left: 30px;	
		color: white;

		@media (min-width: 786px) and (max-width: 992px) {
			width: 210px;             
		}

		& .greeting {			
			font-family: "Prata", serif;
			font-weight: 400;
			font-style: italic;
			font-size: 1.6vw;  
			color: white;
			display: inline-block;

			@media (min-width: 786px) and (max-width: 992px) {
				font-size: 1.9vw;             
			}

			@media only screen and (max-width: 786px) {
			    display: none;			    
			}	
		}			
	}

	&__content {
		@extend .pr-content;
		margin-bottom: 45px;
	}

	&__pitchwrap {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__stocktemp {
		margin: 20px;
		display: flex;
		justify-content: center;					
		position: relative;
		padding-bottom: 100px;
		padding-left: 100px;		

		& img {
			width: 97%;
			position: absolute;	
			top: -13px;
			left: 2px;
			z-index: 1;
		}

		& .brief {
			width: 100%;
			position: absolute;	
			top: 30px;
			left: 0;
			z-index: 1;
			background-color: #eebd8c;			
			height: 95px;
			border-bottom-left-radius: 2.5px;
			border-bottom-right-radius: 2.5px;
			box-shadow: 0 1px 1px rgba(0,0,0,0.25);
			
			&:after {
			  content: '';
			  position: absolute;
			  top: 1.5px;
			  width: 100%;
			  height: 8px;
			  border: 0 solid transparent;
			  background-color: #c7a380;				  
			}			
		}
	}

	&__pitch {
		color: #222f3e;
		width: 58%;
		line-height: 1.2;
		@media only screen and (max-width: 786px) {
			display: none;			  
		}
	}

	&__pitch.mobile {
		display: none;
		width: 100%;  

		@media only screen and (max-width: 786px) {
			display: block;     
		}
	}

	&__pitch, &__pitch-mobile {
		margin-top: 20px;

		& .curve {
			font-family: "Prata", serif;
			font-size: 30px;

			@media (min-width: 786px) and (max-width: 992px) {
				font-size: 22px;             
			}

			@media only screen and (max-width: 786px) {
				font-size: 18px;       
			}			
		}

		& .links {
			display: block;			
			font-size: 15px;
			margin-top: 3px;
			text-decoration: none;

			& i {
				font-size: 10px;
				display: inline-block;							
			}
		}

		& .letter-design {
			color: #7319fa;
  			background-image: -webkit-linear-gradient(45deg, #7319fa, #ff33b4, #ff7e29);
  			background-clip: text;
  			-webkit-background-clip: text;
  			text-fill-color: transparent;
  			-webkit-text-fill-color: transparent;
		}

		& .how-it-works {
			background-image: linear-gradient(225deg, #28d8ff, #032eff);
    		filter: drop-shadow(0 20px 30px #28d8ff33);
    		color: black;
    		-webkit-text-fill-color: transparent;
    		-webkit-background-clip: text;
    		-webkit-box-decoration-break: clone;
		}
	}

	&__search {
		margin-top: 20px;	
		padding: 10px;
		// background-color: #ecebeb;

		display: flex;
		justify-content: space-between;
		align-items: center;

		@media only screen and (max-width: 786px) {
			flex-flow: column;
		}		
	}

	&__input {
		flex: 1;
		display: flex;
		justify-content: space-around;		
		height: 30px;		
		border: 1px solid gray;
		background-color: white;
		padding: 0 5px;

		@media only screen and (max-width: 786px) {
			height: 25px;
			align-items: center;
			width: 100%;
		}

		& input {
			height: 100%;
			width: 100%;			
			border: none;
			padding: 2px 2px;

			&:focus {
				outline: none;
			}					
		}

		& i {
			color: gray;

			@media only screen and (max-width: 786px) {
				font-size: 14px;
			}
		}
	}

	&__stocklist {
		& table {
			width: 100%;
			height: 100%;
			position: relative;	
			border-collapse: collapse;
		}

		& table thead {
			position: sticky;
  			top: 0;

  			&.disabled {
  				display: none;
  			}
		}

		& table tr {
  			border: 1px solid white;  				
		}		

		& th, td {			
			text-align: left;
			background-color: #ecebeb;
			padding: 12px 24px;

			@media only screen and (max-width: 786px) {
				font-size: 15px;
				padding: 10px;								
			}
		}

		& th {
			font-weight: 600;
		}

		& td.stock .parent {
			width: 40px;			

			display: flex;
			justify-content: center;
			align-items: center;					
		}

		& td.stock img {			
			width: 100%;
			@media only screen and (max-width: 786px) {
				width: 25px;
			}		
		}

		& td.stock p {
			margin-left: 4px;
			@media only screen and (max-width: 786px) {
				margin-left: 0px;
				margin-top: 3px;
			}
		}

		& td.view {
			font-size: 16px;
			@media only screen and (max-width: 786px) {
				font-size: 13px;
				text-align: center;
			}
		}
	
		& td.action a {
			padding: 4px 10px;
			font-size: 14px;
			border: none;
			border-radius: 2px;			
			color: white;
			text-decoration: none;
			white-space: nowrap;
			background-color: rgb(34, 47, 62);

			@media only screen and (max-width: 786px) {
				font-size: 12px;
				padding: 3px 5px;
			}			
		}

		& tr.skeleton .bar {		
			@extend .skeleton-box;
		}		
	}

	&__table-head {
		padding: 16px;
		background-color: #ecebeb;

		font-size: 18px;
		font-weight: 600;
		font-style: italic;
		text-align: center;
		color: black;
	}

	&__table {
		width: 100%;		

		& .row {
			width: 100%;
			border-top: 1px solid white; 
			background-color: #ecebeb;

			&.skeleton {		
				@extend .skeleton-box;
				height: 60px;
			}

			@media only screen and (max-width: 786px) {				
				display: flex;
				justify-content: center;				
			}			
		}

		& .container {				
			min-width: 340px; 					
			padding: 12px 25px;
			display: flex;			
			align-items: center;
			@media only screen and (max-width: 786px) {
				padding: 25px;
				min-width: 322px;			
				align-items: flex-start;				
			}									
		}

		& .logo {			
			flex: 0 0 40px;					
			display: flex;
			align-items: center;

			& img {
				width: 100%;
			}

			@media only screen and (max-width: 786px) {
				width: 52px;
				flex: 0 0 52px;								
			}
		}

		& .details {						
			flex-grow: 1;
			margin-left: 30px;
			display: flex;
			justify-content: space-between;

			@media only screen and (max-width: 786px) {
				margin-left: 20px;			
				flex-flow: column;
				margin-top: -2px;
				flex-grow: 0;
			}
		}

		& .name {
			min-width: 240px;						

			@media (min-width: 786px) and (max-width: 1025px) {
				min-width: 170px;				            
			}

			@media only screen and (max-width: 786px) {
				min-width: 0;
				font-size: 20px;
			}			
		}

		& .price-view {				
			min-width: 280px;
			margin-left: 20px;
			display: flex;						

			& p {				
				display: inline;
				min-width: 100px;

				& span {
					display: none;
					font-size: 16px;
					color: #323131;

					@media only screen and (max-width: 786px) {				
						display: inline;				
					}
				}

				@media (min-width: 786px) and (max-width: 1025px) {
					min-width: 80px;									            
				}

				@media only screen and (max-width: 786px) {				
					width: unset;
					font-size: 19px;				
				}				
			}

			& a {
				display: block;
				margin-left: 20px;								
				font-size: 16px;

				@media (min-width: 786px) and (max-width: 1025px) {
					margin-left: 10px;
					font-size: 15px;									            
				}

				@media only screen and (max-width: 786px) {				
					margin-left: 0;
					font-size: 15px;				
				}								
			}

			@media (min-width: 786px) and (max-width: 1025px) {
				min-width: 0;
				width: auto;									            
			}

			@media only screen and (max-width: 786px) {				
				margin-left: 0;
				margin-top: 6px;
				min-width: 0;
				width: auto;

				flex-flow: column;								
			}
		}

		& .actions {			
			margin-left: 20px;	

			@media only screen and (max-width: 786px) {				
				margin-left: 0;
				margin-top: 20px;				
			}

			& a {
				padding: 4px 10px;
				font-size: 14px;
				border: none;
				border-radius: 2px;			
				color: white;
				text-decoration: none;
				white-space: nowrap;
				background-color: rgb(34, 47, 62);

				@media only screen and (max-width: 786px) {
					font-size: 13px;
					padding: 3px 10px;
				}
			}
		}
	}

	&__unfound {
		margin-top: 5px;		
		font-size: 14px;
		color: gray;
	}	
}
